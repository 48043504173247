$platform-name: "dorsetsomersetairambulance";
$assets-path: "../assets/";

/* Fonts */
@font-face {
  font-family: "Figtree";
  src: url($assets-path + "Figtree-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url($assets-path + "Figtree-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url($assets-path + "Figtree-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Figtree";
  src: url($assets-path + "Figtree-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url($assets-path + "OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url($assets-path + "OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url($assets-path + "OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url($assets-path + "OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Typography
$font-family-base: "Figtree", sans-serif;
$headings-font-family: "Figtree", sans-serif;
$font-size-base: 16px;
$font-size-large: 1.25rem;
$font-size-small: 0.875rem;

$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$headings-font-weight: 800;

$font-size-h1: 39.808px;
$font-size-h2: 33.184px;
$font-size-h3: 27.648px;
$font-size-h4: 23.04px;
$font-size-h5: 19.2px;
$font-size-h6: 16px;

$font-mobile-h1: 33.8368px;
$font-mobile-h2: 28.2064px;
$font-mobile-h3: 23.5008px;
$font-mobile-h4: 19.584px;
$font-mobile-h5: 16.32px;
$font-mobile-h6: 16px;

$font-display-1: 119px;
$font-display-2: 95px;
$font-display-3: 76px;
$font-display-4: 61px;

$mobile-display-1: 101.32px;
$mobile-display-2: 81.056px;
$mobile-display-3: 64.845px;
$mobile-display-4: 51.884px;

$line-height-h1: 1.35;
$line-height-h2: 1.30;
$line-height-h3: 1.30;
$line-height-h4: 1.25;
$line-height-h5: 1.50;
$line-height-h6: 1.50;

// Colours
$brand-primary: #075E54;
$brand-secondary: #25D366;
$brand-tertiary: #FFD700;
$brand-quarternary: #F71735;
$brand-quinary: #005241;
$brand-senary: #033530;

// Misc
$body-colour: #000000;
$headings-colour: #075E54;

// Greyscale
$black: #000000;
$white: #FFFFFF;

// Background Palette
$bg-color: #F8F8F8;
$bg-light: #FCF5EB;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 1.5s;
$transition-curve: ease;

// Layout
$container-grid-size: 85%;
$container-max-width: 1600px;
$post-max-width: 1000px;
$site-bleed: 0px;

// Logo
$logo: "logo.png";
$logo-width: 198px;
$logo-height: 62.469px;
$logo-breakpoint: 767px;
$logo-mobile-width: $logo-width * 0.8;
$logo-mobile-height: $logo-height * 0.8;

// Buttons
$btn-background-colour: $brand-tertiary;
$btn-text-colour: $brand-quinary;
$btn-border-radius: 500px;
$btn-box-shadow: none;
$btn-padding-x: 25px;
$btn-padding-y: 15px;
$btn-font-family: $font-family-base;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-line-height: 19.2px;
$btn-letter-spacing: 0;

// Menu admin
$menu-admin-enabled: false;

// Donate button
$donate-btn-background-colour: $brand-quinary;
$donate-btn-colour: $brand-secondary;

// Header
$header-background-colour: $white;

// Header content
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;

// Header search
$header-search-enabled: false;

// Main call to action
$cta-btn-gap: 0;
$cta-btn-breakpoint: 767px;

// Navigation
$nav-breakpoint: 1329px;
$nav-background-colour: $white;

// Top level items
$nav-top-level-item-padding: 2.125rem 1rem;
$nav-top-level-item-colour: $brand-quinary;
$nav-top-level-item-font-size: 18px;
$nav-top-level-item-font-weight: $font-weight-semibold;
$nav-top-level-item-hover-background-colour: none;
$nav-top-level-chevrons-enabled: true;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $brand-quinary;
$nav-submenu-border-radius: 0;
$nav-submenu-item-padding: 1rem 1rem;
$nav-submenu-item-colour: $white;
$nav-submenu-item-font-size: 18px;
$nav-submenu-item-hover-colour: $brand-secondary;
$nav-submenu-item-hover-background-colour: none;
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $brand-secondary;

// Nav normal
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-mobile-background-colour: $brand-quinary;
$nav-normal-mobile-top-level-item-colour: $white;
$nav-normal-mobile-submenu-background-colour: $brand-primary;
$nav-normal-mobile-submenu-item-colour: $white;

// Menu admin
$menu-admin-enabled: false;

// Context container
$context-container-background-colour: $bg-color;

// Shop
$basket-link-only-on-shop-pages: true;

// Departments list
$department-list-background-colour: $bg-color;

// Footer
$footer-boxes-count: 6;
$footer-margin-y: 0;
$footer-padding-y: 0;
$footer-background-colour: $brand-primary;
$footer-colour: $white;
$footer-link-colour: white;
$footer-link-decoration: none;
$footer-link-hover-colour: $brand-secondary;
$footer-link-hover-decoration: underline;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: false;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: false;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: $white;
$newsletter-heading-font-size: inherit;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-margin-bottom: 0;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 64px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $brand-secondary;
$newsletter-button-colour: $brand-primary;
$newsletter-button-border-colour: $brand-secondary;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: 0;
$newsletter-hero-contents-max-width: 100%;

// Quick giving panel
$quick-giving-background-colour: transparent;
$quick-giving-padding: 0;
$quick-giving-donation-amount-figure-font-size: $font-size-h3;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: transparent;
$quick-giving-donation-amount-figure-colour: $brand-primary;
$quick-giving-donation-amount-border-width: 2px;
$quick-giving-donation-amount-border-colour: $brand-primary;
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: $brand-primary;

// Home quick giving panel
$home-quick-giving-padding-y: 2rem;
$home-quick-giving-donation-amount-background-colour: $quick-giving-donation-amount-background-colour;
$home-quick-giving-donation-amount-figure-colour: $quick-giving-donation-amount-figure-colour;
$home-quick-giving-donation-amount-border-width: $quick-giving-donation-amount-border-width;
$home-quick-giving-donation-amount-border-colour: $quick-giving-donation-amount-border-colour;
$home-quick-giving-donation-amount-selected-background-colour: $quick-giving-donation-amount-selected-background-colour;
$home-quick-giving-donation-amount-selected-figure-colour: $quick-giving-donation-amount-selected-figure-colour;
$home-quick-giving-donate-btn-background-colour: $brand-tertiary;
$home-quick-giving-donate-btn-colour: $brand-primary;
$home-quick-giving-donate-btn-border-colour: $brand-tertiary;
$home-quick-giving-donate-btn-font-size: $font-size-large;
$home-quick-giving-colour: $white; // This should cover any headings and text you have in the snippet
$home-quick-giving-heading-colour: $white;
$home-quick-giving-heading-font-size: $font-size-h3;

// Impact stats
$impact-stats-padding-y: 0;
$impact-stats-background-colour: transparent;
$impact-stats-colour: inherit;
$impact-stats-text-align: left; // left, center, right
$impact-stats-content-max-width: $container-max-width !default;
$impact-stats-breakpoint: 0; // When do you want to break the overall layout from row to column?
$impact-stats-overall-container-direction: row; // column, row for the .homeImpactWrapper

// Heading
$impact-stats-heading-margin-bottom: 1rem;

// Individual stat
$impact-stat-margin: 0;

// Figure
$impact-stats-figure-colour: $brand-secondary;
$impact-stats-figure-font-size: $font-display-4;

// Summary
$impact-stats-summary-colour: $white;

// Home features
$home-features-background-colour: transparent;
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;

// Home feeds
$home-feeds-max-width: 100%;
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 0;

// Carousel
$carousel-max-width: 100%;
$carousel-breakpoint: 767px;
$carousel-details-style: below; // overlay, adjacent or below
$carousel-details-position-y: top; // top, center, bottom
$carousel-details-position-x: left; // left, center, right
$carousel-details-background-colour: transparent;
$carousel-details-padding: 0;
$carousel-heading-colour: $white;
$carousel-heading-font-size: $font-display-3;
$carousel-summary-colour: $white;
$carousel-summary-font-size: $font-size-h3;
$carousel-read-more-font-size: $font-size-large;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: true;

// Carousel buttons dots
$carousel-dots-position-y: bottom; // top, bottom
$carousel-dots-position-x: left; // left, center, right
$carousel-dots-padding: 0 0 10rem;
$carousel-dots-active-colour: $white;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: transparent;
$carousel-mobile-heading-colour: $white;
$carousel-mobile-summary-colour: $white;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;

// Card
$card-border: 0;
$card-box-shadow: none;
$card-image-background-colour: transparent;
$card-details-padding: 0;
$card-details-background-colour: transparent;
$card-heading-colour: $brand-primary;
$card-heading-font-size: $font-size-h4;
$card-summary-colour: $body-colour;
$card-footer-background-colour: transparent;
$card-footer-padding: 0;

// Card hover state
$card-hover-image-opacity: 1;

// Social icons
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-width: 28px;
$social-icons-height: 28px;
$social-icons-font-size: 28px;
$social-icons-gap: 0;
$social-icons-border-radius: 0;
$social-icons-box-shadow: none;

// Slick
$slick-enabled: true;

// Blog details
$blog-details-background-colour: transparent;
$blog-details-padding: 0;

// Sidebar
$sidebar-layout: below; // adjacent, below or above - where do you want the sidebar to be positioned on posts?

// Associated lists
$associated-latest-enabled: false;
$associated-related-enabled: true;
$associated-most-read-enabled: false;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-item-image-enabled: true;
$associated-list-heading-font-size: $font-size-h4;
$associated-item-heading-colour: $headings-colour;
$associated-item-border: false;

// Blockquote
$blockquote-background-colour: $white;
$blockquote-text-align: left; // left, center, right
$blockquote-font-size: $font-size-h2; // Em unit will cascade to children
$blockquote-line-height: 46.8px;
$blockquote-padding: 3rem 3rem;
$blockquote-colour: $brand-primary;

// FAQs
$faq-item-header-background-colour: $brand-primary;
$faq-item-header-padding: 1.5rem;
$faq-item-header-font-size: $font-size-large;
$faq-item-btn-background-colour: $brand-secondary;
$faq-item-btn-chevron-colour: $brand-primary;
$faq-item-hover-btn-background-colour: $brand-secondary;
$faq-item-hover-btn-chevron-colour: $brand-primary;
